<template>
  <v-container>
    <!-- <v-img
      :src="require('../assets/rigoLogo.png')"
      class="my-3"
      contain
      height="200"
    /> -->
    <v-card elevation="2" outlined>
      <v-card-title class="pb-0">
        <span class="text-h5">Filtrar por</span>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submit" ref="form">
          <v-row class="text-center">
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Código</v-subheader>
              <v-text-field v-model="codigo" label="Código" dense solo>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Descripción</v-subheader>
              <v-text-field
                v-model="descripcion"
                label="Descripción"
                dense
                solo
              >
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Num. original</v-subheader>
              <v-text-field v-model="referencia" label="Num. original" dense solo>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Medida</v-subheader>
              <v-text-field v-model="medida" label="Medida" dense solo>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Motor</v-subheader>
              <v-text-field v-model="motor" label="Motor" dense solo>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="2">
              <v-subheader>Fabricante No.</v-subheader>
              <v-text-field v-model="fabricante" label="Fabricante" dense solo>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="3">
              <v-subheader>Año</v-subheader>
              <v-combobox
                v-model.number="anio"
                :items="anios"
                label="Año"
                solo
                dense
              ></v-combobox>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="4">
              <v-subheader>Marca</v-subheader>
              <v-combobox
                v-model="marcaId"
                :items="marcas"
                item-text="marcaNombre"
                item-value="marcaNombre"
                class="mx-4"
                dense
                hide-no-data
                hide-details
                label="Marca"
                solo
                clearable
                :return-object="false"
                @change="modeloId==null"
              ></v-combobox>
            </v-col>
            <v-col class="pa-0 pr-2" cols="12" sm="5">
              <v-subheader>Modelo</v-subheader>
              <div class="d-flex">
                <v-combobox
                  v-model="modeloId"
                  :items="modeloitems"
                  item-value="modeloNombre"
                  item-text="modeloNombre"
                  class="mx-4"
                  dense
                  hide-no-data
                  hide-details
                  label="Modelo"
                  solo
                  clearable
                  :return-object="false"
                ></v-combobox>
                <v-btn color="primary" class="mr-1" type="submit">
                  Buscar
                </v-btn>
                <v-btn
                  color="#d4545e"
                  @click="limpiarCampos"
                  class="btn-limpiar"
                >
                  Limpiar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card elevation="2" outlined class="mt-6">
      <v-card-title class="pb-0">
        <!-- <h6 class="text">2020-BMW-ABCD</h6> -->
        <piezas-form
          v-if="isLoggedIn"
          :opendialog="dialog"
          v-on:toggle-dialog="toggleDialog"
          :piezaId="selectedPiezaId"
        ></piezas-form>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="buscar"
          append-icon="mdi-magnify"
          color="#3e206e"
          label="Buscar"
          class="filtro"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text class="card-content">
        <template>
          <v-data-table
            :headers="_headers"
            :items="piezas"
            :items-per-page="10"
            :search="buscar"
            class="elevation-1"
            :loading="loadingCampos"
            loading-text="Buscando... Por favor espere!"
          >
            <template v-slot:[`item.codigo`]="{ item }">
              <router-link
                :to="{ name: 'Detalle', params: { id: item.piezaId } }"
                @click.native="saveItems"
                color="black"
              >
                {{ item.codigo }}
              </router-link>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item.piezaId)"
                v-if="isLoggedIn"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </template></v-card-text
      ></v-card
    ></v-container
  >
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import PiezasForm from "../components/PiezasForm.vue";
export default {
  name: "Home",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "Código",
        align: "start",
        sortable: false,
        value: "codigo",
        show: true,
      },
      { id: 2, text: "Decripción", value: "nombre", show: true },
      { id: 3, text: "Inicio", value: "anioInicio", show: true },
      { id: 4, text: "Fin", value: "anioFin", show: true },
      { id: 5, text: "Versión", value: "versionNombre", show: true },
      { id: 6, text: "Marca", value: "marcaNombre", show: true },
      { id: 7, text: "Modelo", value: "modeloNombre", show: true },
      { id: 8, text: "Aplicación", value: "aplicacion", show: true },
      { id: 9, text: "Num. original", value: "referencia", show: true },
      { id: 10, text: "Motor", value: "motor", show: true },
      { id: 11, text: "No. serie", value: "serie", show: true },
      { id: 12, text: "Fabricante #", value: "fabricanteNo", show: true },
      { id: 13, text: "Medida", value: "medida", show: true },
      {
        id: 14,
        text: "Acciones",
        value: "actions",
        sortable: false,
        show: true,
      },
    ],
    selectedPiezaId: 0,
    modeloitems: [],
    dialog: false,
    marcaId: null,
    modeloId: null,
    anio: null,
    codigo: "",
    descripcion: "",
    referencia: "",
    medida: "",
    motor: "",
    fabricante: "",
    buscar: "",
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
    loadingCampos: false,
    piezas: [],
    marcas: [],
    modelos: [],
    filtroModelos: [],
  }),
  methods: {
    saveItems() {
      let vm = this;
      var data = {
        marcaId: vm.marcaId,
        modeloId: vm.modeloId,
        anio: vm.anio,
        codigo: vm.codigo,
        descripcion: vm.descripcion,
        referencia: vm.referencia,
        medida: vm.medida,
        motor: vm.motor,
        fabricante: vm.fabricante,
      };

      // Put the object into storage
      localStorage.setItem("datos", JSON.stringify(data));
    },
    toggleDialog(data) {
      this.dialog = data;
      if (!data) {
        this.selectedPiezaId = 0;
      }
    },
    submit() {
      let vm = this;
      vm.loadingCampos = true;
      var data = {
        marcaId: vm.marcaId,
        modeloId: vm.modeloId,
        anio: vm.anio,
        codigo: vm.codigo,
        descripcion: vm.descripcion,
        referencia: vm.referencia,
        medida: vm.medida,
        motor: vm.motor,
        fabricante: vm.fabricante,
      };
      this.$store.dispatch("GET_PIEZAS", data).then((d) => {
        vm.piezas = d;
        vm.loadingCampos = false;
      });
    },
    obtenerModelos() {
      this.$store.dispatch("GET_MODELOS", this.marcaId);
    },
    editItem(id) {
      this.selectedPiezaId = id;
      this.dialog = true;
    },
    limpiarCampos() {
      let vm = this;
      vm.marcaId = null;
      vm.modeloId = null;
      vm.anio = null;
      vm.codigo = "";
      vm.descripcion = "";
      vm.referencia = "";
      vm.medida = "";
      vm.motor = "";
      vm.fabricante = "";
    },
    modelosFiltro() {
      let vm = this;
      vm.modeloitems = [];
      if (vm.marcaId != null) {
        vm.filtroModelos = vm.modelos.filter(
          (a) => !a.marcaNombre.indexOf(vm.marcaId)
        );
      } else {
        vm.filtroModelos = vm.modelos;
      }
      vm.modeloitems = vm.filtroModelos.filter(
        (e) => !e.modeloNombre.indexOf(vm.modeloId) || vm.modeloId == null
      );
    },
  },
  mounted() {
    let vm = this;
    this.$store.dispatch("GET_MARCAS").then((d) => {
      vm.marcas = d;
    });
    this.$store.dispatch("GET_MODELOS").then((d) => {
      vm.modelos = d;
      vm.modelosFiltro();
    });
    var d = localStorage.getItem("datos");
    var datos = JSON.parse(d);
    if (datos) {
      vm.marcaId = datos.marcaId;
      vm.modeloId = datos.modeloId;
      vm.searchModelo = datos.modeloId;
      vm.anio = datos.anio;
      vm.codigo = datos.codigo;
      vm.descripcion = datos.descripcion;
      vm.referencia = datos.referencia;
      vm.medida = datos.medida;
      vm.motor = datos.motor;
      vm.fabricante = datos.fabricante;
      localStorage.removeItem("datos");
      vm.submit();
    }
  },
  computed: {
    _headers() {
      let array = this.headers.find((a) => a.id == 14);
      array.show = this.isLoggedIn ? true : false;
      return this.headers.filter((x) => x.show);
    },
    anios() {
      let list = [];
      let date = new Date().getFullYear();
      for (let index = date; index >= 1930; index--) {
        list.push(index);
      }
      return list;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  components: {
    PiezasForm,
  },
  watch: {
    marcaId(val) {
      let vm = this;
      vm.modelosFiltro();
      //vm.modeloId = null;
    },
    modeloId(val) {
      let vm = this;
      vm.modelosFiltro();
    },
  },
};
</script>
<style >
.filtro {
  max-width: 150px !important;
}
.btn-limpiar {
  color: white !important;
}
.card-content {
  max-height: 380px;
}
.v-data-table-header th {
  white-space: nowrap;
}
@media screen and (max-height: 734px) {
  .card-content {
    max-height: 310px !important;
  }
}
</style>