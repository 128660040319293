<template>
  <v-row class="pl-6 mb-2">
    <v-dialog v-model="opendialog" persistent max-width="600px">
      <template v-slot:activator="{ attrs }">
        <v-btn color="#d4545e" text dark v-bind="attrs" @click="$emit('toggle-referencias',true)" >
          Agregar referencia
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-form ref="form" @submit.prevent="guardar">
        <v-card>
          <v-card-title>
            <span class="text-h5">Referencia</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-container>
              <v-row>
                <v-col cols="12"  class="inputStyle">
                  <v-subheader>Código de referencia</v-subheader>
                  <v-text-field
                    v-model="model.codigo"
                    :rules="[rules.required]"
                    label="Código"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('toggle-referencias',false)">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" type="submit" text> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    // model: {
    //   piezaId: 0,
    //   codigo: '',
    //   referenciaId:0,
    // },
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
  }),
  methods: {
    guardar() {
      let vm = this;
      if (vm.$refs.form.validate()) {
        vm.$emit("formsubmit", vm.model);
        vm.$emit('toggle-referencias',false)
      }
    },
  },
  props: ["opendialog", "modeloItem"],
    computed: {
    model() {
      let item = Object.assign({}, this.modeloItem);
      return item;
    },
  },
};
</script>
<style >
.inputStyle {
  padding: 0 4px 0 4px !important;
}
</style>