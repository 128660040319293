<template>
  <v-row class="pl-6 mb-2">
    <v-dialog v-model="opendialog" persistent max-width="600px">
      <template v-slot:activator="{ attrs }">
        <v-btn
          color="#d4545e"
          text
          dark
          v-bind="attrs"
          @click="$emit('toggle-usos', true)"
        >
          Agregar uso
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-form ref="form" @submit.prevent="guardar">
        <v-card>
          <v-card-title>
            <span class="text-h5">Agregar uso</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-container>
              <v-row>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Marca</v-subheader>
                  <v-select
                    :items="marcas"
                    v-on:change="actualizarModelos"
                    item-text="marcaNombre"
                    item-value="marcaId"
                    :rules="[rules.required]"
                    label="Seleccione"
                    v-model="model.marca"
                    return-object
                    dense
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Modelo</v-subheader>
                  <v-select
                    :items="modeloitems"
                    item-value="modeloId"
                    item-text="modeloNombre"
                    :rules="[rules.required]"
                    v-model="model.modelo"
                    return-object
                    label="Seleccione"
                    dense
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Versión</v-subheader>
                  <v-select
                    :items="versiones"
                    item-value="versionId"
                    item-text="versionNombre"
                    :rules="[rules.required]"
                    v-model="model.version"
                    return-object
                    label="Seleccione"
                    dense
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Año Inicio</v-subheader>
                  <v-text-field
                    v-model.number="model.anioInicio"
                    :rules="[rules.required]"
                    label="Inicio"
                    solo
                    type="number"
                    @input="validarAnio"
                    :error-messages="
                      validarAnio()
                        ? []
                        : ['El Año de inicio no puede ser mayor al año fin']
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Año Fin</v-subheader>
                  <v-text-field
                    v-model.number="model.anioFin"
                    :rules="[rules.required]"
                    label="Fin"
                    type="number"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Aplicación</v-subheader>
                  <v-text-field
                    v-model="model.aplicacion"
                    label="Aplicación"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Serie</v-subheader>
                  <v-text-field
                    v-model="model.serie"
                    label="Serie"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Motor</v-subheader>
                  <v-text-field
                    v-model="model.motor"
                    label="Motor"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="inputStyle">
                  <v-subheader>Posición</v-subheader>
                  <v-text-field
                    v-model="model.posicion"
                    label="Posición"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('toggle-usos', false)"
            >
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" type="submit" text> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    marca: { marcaId: "", marcaNombre: "" },
    modelo: { modeloId: "", modeloNombre: "" },
    version: { versionId: "", versionNombre: "" },
    modelos: [],
    filtroModelos: [],
    modeloitems: [],
    marcaId: 0,
    isMounted: false,
    model: {},
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
  }),
  methods: {
    guardar() {
      let vm = this;
      if (vm.$refs.form.validate()) {
        vm.$emit("formsubmit", vm.model);
        vm.$emit("toggle-usos", false);
      }
    },
    obtenerModelos(data) {
      this.$store.dispatch("GET_MODELOS").then((d) => {
        this.modelos = d;
        this.modelosFiltro();
      });
    },
    actualizarModelos() {
      if (this.isMounted) this.model.modelo = null;
      console.log("es aqui");
      this.modelosFiltro();
      this.isMounted = true;
    },
    modelosFiltro() {
      let vm = this;
      vm.modeloitems = [];

      if (vm.model.marca.marcaId != null) {
        vm.filtroModelos = vm.modelos.filter(
          (a) => a.marcaId == vm.model.marca.marcaId
        );
      } else {
        vm.filtroModelos = vm.modelos;
      }
      vm.modeloitems = vm.filtroModelos;
      // vm.modeloitems = vm.filtroModelos.filter(
      //   (e) => !e.modeloId==vm.modeloId || vm.modeloId == null
      // );
    },
    validarAnio() {
      let vm = this.model;
      if (vm.anioInicio > vm.anioFin) {
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapState(["marcas"]),
    ...mapState(["versiones"]),
  },
  props: ["opendialog", "modeloItem"],
  watch: {
    opendialog: function (newValue, oldValue) {
      let vm = this;
      if (newValue) {
        this.model = Object.assign({}, this.modeloItem);
        this.$store.dispatch("GET_VERSIONES");
        this.obtenerModelos();
      }
    },
  },
};
</script>
<style >
.inputStyle {
  padding: 0 4px 0 4px !important;
}
</style>
