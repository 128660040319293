<template>
  <v-row class="pb-3">
    <v-dialog
      v-model="opendialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ attrs }">
        <v-btn
          color="#d4545e"
          dark
          v-bind="attrs"
          @click="$emit('toggle-dialog', true)"
          text
        >
          Agregar pieza
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-form @submit.prevent="submit" ref="form">
        <v-card justify="center">
          <v-toolbar dark color="#3e206e">
            <v-btn icon dark @click="$emit('toggle-dialog', false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Agregar pieza</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text type="submit"> Guardar </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!-- Agregar elementos -->
          <v-subheader>Pieza</v-subheader>

          <v-row class="pl-4 pr-4 mb-2">
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Código</v-subheader>
              <v-text-field
                v-model="model.codigo"
                :rules="[rules.required]"
                label="Código"
                solo
                @change="obtenerPieza(0, model.codigo)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Nombre</v-subheader>
              <v-text-field
                v-model="model.nombre"
                :rules="[rules.required]"
                label="Nombre"
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Fabricante No.</v-subheader>
              <v-text-field
                v-model="model.fabricanteNo"
                label="Fabricante No."
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Marca</v-subheader>
              <v-select
                :items="marcas"
                @change="actualizarModelos"
                item-value="marcaId"
                item-text="marcaNombre"
                :rules="[rules.required]"
                v-model="model.marcaId"
                label="Seleccione"
                dense
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Modelo</v-subheader>
              <v-select
                :items="modeloitems"
                item-value="modeloId"
                item-text="modeloNombre"
                v-model="model.modeloId"
                label="Seleccione"
                dense
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" class="inputStyle">
              <v-subheader>Fabricación</v-subheader>
              <v-select
                :items="fabricaciones"
                item-value="fabricacionId"
                item-text="fabricacionNombre"
                :rules="[rules.required]"
                v-model="model.fabricacionId"
                label="Seleccione"
                dense
                solo
              ></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-subheader>Usos</v-subheader>
          <usos-form
            v-on:formsubmit="agregarUso"
            :modeloItem="modelU"
            :opendialog="dialogU"
            v-on:toggle-usos="toogleUsos"
          ></usos-form>
          <v-simple-table fixed-header height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Marca</th>
                  <th class="text-left">Modelo</th>
                  <th class="text-left">Versión</th>
                  <th class="text-left">Año Inicio</th>
                  <th class="text-left">Año Fin</th>
                  <th class="text-left">Serie</th>
                  <th class="text-left">Motor</th>
                  <th class="text-left">Aplicación</th>
                  <th class="text-left">Posición</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in model.piezasUsos" :key="index">
                  <td>{{ item.marca.marcaNombre }}</td>
                  <td>{{ item.modelo.modeloNombre }}</td>
                  <td>{{ item.version.versionNombre }}</td>
                  <td>{{ item.anioInicio }}</td>
                  <td>{{ item.anioFin }}</td>
                  <td>{{ item.serie }}</td>
                  <td>{{ item.motor }}</td>
                  <td>{{ item.aplicacion }}</td>
                  <td>{{ item.posicion }}</td>
                  <td>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="editUso(item, index)">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="removeItem(index, model.piezasUsos)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <!-- <tr v-if="model.piezasUsos.length==0">
                <td colspan="10" class="td-text">
                  No se ha agregado ningun elemento
                </td>
              </tr> -->
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <v-subheader>Unidades de medida</v-subheader>
          <UnidadMedidasForm
            v-on:formsubmit="agregarUnidadMedida"
            :opendialog="dialogM"
            :modeloItem="modelM"
            v-on:toggle-unidades="toogleUnidades"
          />
          <v-simple-table fixed-header height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Medida 1</th>
                  <th class="text-left">Medida 2</th>
                  <th class="text-left">Medida 3</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in model.piezasUnidadesMedida"
                  :key="index"
                >
                  <td>{{ item.medida1 }}</td>
                  <td>{{ item.medida2 }}</td>
                  <td>{{ item.medida3 }}</td>
                  <td>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="editUnidad(item, index)">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="removeItem(index, model.piezasUnidadesMedida)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <!-- <tr v-if="model.piezasUnidadesMedida.length==0">
                <td colspan="4" class="td-text">
                  No se ha agregado ningun elemento
                </td>
              </tr> -->
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <v-subheader>Referencias</v-subheader>
          <ReferenciasForm
            v-on:formsubmit="agregarReferencia"
            :opendialog="dialogR"
            :modeloItem="modelR"
            v-on:toggle-referencias="toogleReferencias"
          />
          <v-simple-table fixed-header height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Código</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in model.referencia" :key="index">
                  <td>{{ item.codigo }}</td>
                  <td>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="editReferencia(item, index)">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="removeItem(index, model.referencia)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <!-- <tr v-if="model.piezasUnidadesMedida.length==0">
                <td colspan="2" class="td-text">
                  No se ha agregado ningun elemento
                </td>
              </tr> -->
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import UsosForm from "../components/UsosForm.vue";
import UnidadMedidasForm from "../components/UnidadMedidasForm.vue";
import ReferenciasForm from "../components/ReferenciasForm.vue";
export default {
  name: "PiezasForm",
  data: () => ({
    dialog: false,
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
    dialogU: false,
    modelU: {},
    dialogM: false,
    modelM: {},
    dialogR: false,
    modelR: {},
    indexU: null,
    indexM: null,
    indexR: null,
    modelos: [],
    filtroModelos: [],
    modeloitems: [],
    isMounted: false,
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        let vm = this;
        vm.model.esManual = false;
        // vm.model.creadoPor = parseInt(vm.$store.getters.userId;
        /*
          let x = {
          codigo: "fb1234",
          creadoPor: 1,
          esManual: true,
          estadoId: 1,
          fabricacionId: 1,
          fabricanteNo: "121212",
          marcaId: 58,
          modeloId: 687,
          modificadoPor: 1,
          nombre: "Freno de mano",
          piezaId: 0,
        };
  */
        this.$store.dispatch("AGREGAR_PIEZA", vm.model).then((d) => {
          if (d) vm.$emit("toggle-dialog", false);
        });
      }
    },
    removeItem(index, array) {
      array.splice(index, 1);
    },
    editUso(item, index) {
      this.modelU = item;
      this.dialogU = true;
      this.indexU = index;
    },
    toogleUsos(data) {
      let vm = this;
      if (data) {
        this.indexU = null;
        this.modelU = {
          id: 0,
          piezaId: 0,
          anioFin: 0,
          anioInicio: 0,
          aplicacion: "",
          marcaId: 0,
          // marca: {},
          modeloId: 0,
          // modelo: {},
          motor: "",
          posicion: "",
          serie: "",
          versionId: 0,
          // version: {},
          // creadoPor: vm.$store.getters.userId,
        };
      }
      this.dialogU = data;
    },
    toogleUnidades(data) {
      let vm = this;
      if (data) {
        this.indexM = null;
        this.modelM = {
          id: 0,
          medida1: "",
          medida2: "",
          medida3: "",
          // creadoPor: vm.$store.getters.userId,
        };
      }
      this.dialogM = data;
    },
    toogleReferencias(data) {
      if (data) {
        this.indexR = null;
        this.modelR = {
          codigo: "",
          referenciaId: 0,
          piezaId: 0,
        };
      }
      this.dialogR = data;
    },
    editUnidad(item, index) {
      this.modelM = item;
      this.dialogM = true;
      this.indexM = index;
    },
    editReferencia(item, index) {
      this.modelR = item;
      this.dialogR = true;
      this.indexR = index;
    },
    actualizarModelos() {
      if (this.isMounted) this.model.modeloId = null;

      this.modelosFiltro();
      this.isMounted = true;
    },
    obtenerModelos() {
      this.$store.dispatch("GET_MODELOS", this.model.marcaId).then((d) => {
        this.modelos = d;
      });
    },
    modelosFiltro() {
      let vm = this;
      vm.modeloitems = [];
      if (vm.model.marcaId != null) {
        vm.filtroModelos = vm.modelos.filter(
          (a) => a.marcaId == vm.model.marcaId
        );
      } else {
        vm.filtroModelos = vm.modelos;
      }
      vm.modeloitems = vm.filtroModelos;
      // vm.modeloitems = vm.filtroModelos.filter(
      //   (e) => !e.modeloId==vm.modeloId || vm.modeloId == null
      // );
    },
    agregarUso(data) {
      let datos = Object.assign({}, data);
      if (this.indexU != null) {
        let item = this.model.piezasUsos[this.indexU];
        item.piezaId = data.piezaId;
        item.anioFin = data.anioFin;
        item.anioInicio = data.anioInicio;
        item.aplicacion = data.aplicacion;
        item.marcaId = data.marca.marcaId;
        item.modeloId = data.modelo.modeloId;
        item.motor = data.motor;
        item.posicion = data.posicion;
        item.serie = data.serie;
        item.versionId = data.version.versionId;
      } else {
        datos.marcaId = data.marca.marcaId;
        datos.modeloId = data.modelo.modeloId;
        datos.versionId = data.version.versionId;
        this.model.piezasUsos.push(datos);
      }
    },
    agregarUnidadMedida(data) {
      let datos = Object.assign({}, data);
      if (this.indexM != null) {
        let item = this.model.piezasUnidadesMedida[this.indexM];
        item.medida1 = datos.medida1;
        item.medida2 = datos.medida2;
        item.medida3 = datos.medida3;
        item.piezaId = datos.piezaId;
      } else {
        this.model.piezasUnidadesMedida.push(datos);
      }
    },
    agregarReferencia(data) {
      let a = {
        referenciaId: data.referenciaId,
        piezaId: data.piezaId,
        codigo: data.codigo,
      };

      let datos = Object.assign({}, a);
      if (this.indexR != null) {
        let item = this.model.referencia[this.indexR];
        item.codigo = datos.codigo;
      } else {
        this.model.referencia.push(datos);
      }
    },
    obtenerPieza(id, codigo) {
      let data = {
        id: id,
        codigo: codigo,
      };
      this.$store.dispatch("GET_PIEZA", data);
    },
  },
  watch: {
    opendialog: function (newValue, oldValue) {
      let vm = this.model;
      if (newValue) {
        this.obtenerPieza(this.piezaId);
        this.obtenerModelos();
      }
    },
    marcaId(val) {
      this.modelosFiltro();
    },
  },
  computed: {
    ...mapState(["marcas"]),
    ...mapState(["fabricaciones"]),
    ...mapState(["model"]),
    marcaId() {
      return this.model.marcaId;
    },
  },
  mounted() {
    this.$store.dispatch("GET_MARCAS");
    this.$store.dispatch("GET_Fabricaciones");
    this.obtenerModelos();
  },
  props: ["piezaId", "opendialog"],
  components: {
    UsosForm,
    UnidadMedidasForm,
    ReferenciasForm,
  },
};
</script>
<style >
.inputStyle {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.td-text {
  text-align: center !important;
}
</style>