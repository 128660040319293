<template >
  <div>
    <v-container>
      <v-btn color="primary" dark text @click="handledUser(0)">
        Nuevo usuario
      </v-btn>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Código</th>
              <th class="text-left">Usuario</th>
              <th class="text-left">Nombre</th>
              <th class="text-left">Roles</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in usuarios" :key="item.name">
              <td>U-{{ item.usuarioId }}</td>
              <td>{{ item.usuarioNombre }}</td>
              <td>{{ item.nombre }} {{ item.apellido }}</td>
              <td>
                <span v-for="(el, i) in item.usuariosRoles" :key="i">
                  {{ el.rol.rolNombre }}
                </span>
              </td>
              <td>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="handledUser(item.usuarioId)">
                      <v-list-item-icon>
                        <v-icon> mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title> Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="eliminarUsuario(item.usuarioId)">
                      <v-list-item-icon>
                        <v-icon> mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title> Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form @submit.prevent="handleSubmit" ref="form">
        <v-card>
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Usuario"
                    required
                    v-model="model.usuarioNombre"
                    solo
                    clearable
                    :rules="[rules.required]"
                    append-icon="mdi-account-circle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nombre"
                    required
                    v-model="model.nombre"
                    solo
                    clearable
                    :rules="[rules.required]"
                    append-icon="mdi-account"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="apellido"
                    required
                    v-model="model.apellido"
                    solo
                    clearable
                    :rules="[rules.required]"
                    append-icon="mdi-account"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="model.selectedIds"
                    :items="roles"
                    item-text="rolNombre"
                    item-value="rolId"
                    label="Seleccionar rol"
                    multiple
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!editando">
                  <v-text-field
                    label="Contraseña"
                    required
                    v-model="model.contrasena"
                    solo
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.required]"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!editando">
                  <v-text-field
                    label="Confirmar contraseña"
                    v-model="confirmPassword"
                    solo
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    :rules="
                      confirmPasswordRules.concat(passwordConfirmationRule)
                    "
                    @click:append="showConfirm = !showConfirm"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="editando">
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="cambiarContrasenia"
                    label="Cambiar contraseña"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="cambiarContrasenia">
                  <v-text-field
                    label="Contraseña"
                    required
                    v-model="model.contrasena"
                    solo
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.required]"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="cambiarContrasenia">
                  <v-text-field
                    label="Confirmar contraseña"
                    v-model="confirmPassword"
                    solo
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    :rules="
                      confirmPasswordRules.concat(passwordConfirmationRule)
                    "
                    @click:append="showConfirm = !showConfirm"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="invalid"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Usuarios",
  data: () => ({
    dialog: false,
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
    confirmPasswordRules: [(v) => !!v || "Este campo es requerido."],
    confirmPassword: "",
    show: false,
    loading: false,
    invalid: false,
    cambiarContrasenia: false,
    model: {},
    editando: false,
    showConfirm: false,
  }),
  methods: {
    handledUser(id) {
      let vm = this;
      vm.dialog = true;
      vm.editando = id == 0 ? false : true;
      this.$store.dispatch("GET_UserModel", id).then((d) => {
        this.model = d;
      });
    },
    eliminarUsuario(id) {
      let vm = this;
      this.$store.dispatch("Desactivar_Usuario", id).then((d) => {
        if (d.success) vm.GetAllUser();
      });
    },
    handleSubmit() {
      let vm = this;
      const data = {
        usuarioNombre: vm.model.usuarioNombre,
        nombre: vm.model.nombre,
        apellido: vm.model.apellido,
        contrasena: vm.model.contrasena,
        usuarioId: vm.model.usuarioId,
        selectedIds: vm.model.selectedIds,
      };
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Usuario", data).then((d) => {
          if (d.success) {
            vm.GetAllUser();
            vm.dialog = false;
          }
        });
      }
    },
    GetAllUser() {
      this.$store.dispatch("GET_USUARIOS");
    },
  },
  mounted() {
    this.GetAllUser();
    this.$store.dispatch("GET_ROLES");
  },
  computed: {
    ...mapState(["usuarios"]),
    ...mapState(["roles"]),
    passwordConfirmationRule() {
      return () =>
        this.model.contrasena === this.confirmPassword ||
        "Las contraseñas no coinciden.";
    },
  },
};
</script>