<template>
  <v-container>
    <v-card elevation="2" outlined
    v-if="detalle.pieza!=undefined"
    >
      <v-card-title class="card-header">{{detalle.pieza.nombre}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="text-center">
          <v-col class="pa-0 pr-2" cols="12" sm="12">
            <v-img
              :src="require('../assets/rigoLogo.png')"
              height="200"
              contain
            />
          </v-col>
          <v-col class="pa-0 pt-2 pr-2 pl-2" cols="12" sm="6">
            <v-card max-height="400">
              
              <div class="title-card pa-0 pt-2" >
                <h4 class="pl-3">Especificaciones</h4>

                <v-icon class="pr-3" color="#1b2d50" dense>
                  mdi-tools
                </v-icon>
              </div>
              <v-divider></v-divider>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <!-- <thead>
                      <tr>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Valor</th>
                      </tr>
                    </thead> -->
                    <tbody>
                      <tr class="text-left">
                        <td>Código:</td>
                        <td>{{ detalle.pieza.codigo }}</td>
                      </tr>
                      <tr class="text-left">
                        <td>Nombre:</td>
                        <td>{{ detalle.pieza.nombre }}</td>
                      </tr>
                      <tr class="text-left">
                        <td>fabricanteNo:</td>
                        <td>{{ detalle.pieza.fabricanteNo }}</td>
                      </tr>
                      <tr class="text-left">
                        <td>Marca:</td>
                        <td>{{ detalle.pieza.marcaNombre }}</td>
                      </tr>
                      <tr class="text-left">
                        <td>Modelo:</td>
                        <td>{{ detalle.pieza.modeloNombre }}</td>
                      </tr>
                      <tr class="text-left">
                        <td>Fabricación:</td>
                        <td>{{ detalle.pieza.fabricacionNombre }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0 pt-2 pr-2 pl-2" cols="12" sm="6">
            <v-card max-height="400" >
              <div class="title-card pa-0 pt-2">
                <h4 class="pl-3">Referencias</h4>

                <v-icon class="pr-3" color="#1b2d50">
                  mdi-call-split
                </v-icon>
              </div>
              <v-divider></v-divider>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Número de pieza</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in detalle.referencias"
                        :key="index"
                        class="text-left"
                      >
                        <td>{{ item.codigoOriginal }}</td>
                        <td>{{ item.codigo }}</td>
                      </tr>
                      <tr v-if="detalle.referencias.length==0">
                        <td colspan="2">No hay referencias de esta pieza</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0 pr-2 pl-2 pt-4" cols="12" sm="12">
            <v-card max-height="400">
              <div class="title-card pa-0 pt-2">
                <h4 class="pl-3">Vehículos en que se usa</h4>
                <v-icon class="pr-3" color="#1b2d50" dense>
                  mdi-car-multiple
                </v-icon>
              </div>
              <v-divider></v-divider>

              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Inicio</th>
                        <th class="text-center">Fin</th>
                        <th class="text-center">Marca</th>
                        <th class="text-center">Modelo</th>
                        <th class="text-center">Versión</th>
                        <th class="text-center">Serie</th>
                        <th class="text-center">Motor</th>
                        <th class="text-center">Aplicación</th>
                        <th class="text-center">Posición</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in detalle.piezaUsos"
                        :key="index"
                      >
                        <td>{{ item.anioInicio }}</td>
                        <td>{{ item.anioFin }}</td>
                        <td>{{ item.marcaNombre }}</td>
                        <td>{{ item.modeloNombre }}</td>
                        <td>{{ item.versionNombre }}</td>
                        <td>{{ item.serie }}</td>
                        <td>{{ item.motor }}</td>
                        <td>{{ item.aplicacion }}</td>
                        <td>{{ item.posicion }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pa-0 pr-2 pl-2 pt-4" cols="12" sm="12">
            <v-card max-height="400">
              <div class="title-card pa-0 pt-2">
                <h4 class="pl-3">Medidas</h4>
                <v-icon class="pr-3" color="#1b2d50" dense>
                  mdi-ruler
                </v-icon>
              </div>
              <v-divider></v-divider>

              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Medida 1</th>
                        <th>Medida 2</th>
                        <th>Medida 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in detalle.piezaMedidas"
                        :key="index"
                      >
                        <td class="text-left">{{ item.medida1 }}</td>
                        <td class="text-left">{{ item.medida2 }}</td>
                        <td class="text-left">{{ item.medida3 }}</td>
                      </tr>
                       <tr v-if="detalle.piezaMedidas.length==0">
                        <td colspan="3">No hay medidas de esta pieza</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "Detalle",
  data: () => ({
    especificaciones: [],
  }),
  mounted() {
    if (this.$route.params.id == undefined ||this.$route.params.id==0) {
      this.$router.push("/").catch(()=>{});;
    }else{
    this.$store.dispatch("GET_PiezaDetalle", this.$route.params.id);
    }
  },
  computed: {
    ...mapState(["detalle"]),
  },
};
</script>
<style>
.title-card {
  text-align: left !important;
  display: flex !important;
  justify-content: space-between;
  background-color: #e1858b;
}
.title-card h4{
  color: #1b2d50;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>