<template>
  <v-app>
    <v-app-bar app color="white" flat dark>
      <router-link to="/">
        <div class="d-flex align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/rigoLogo.png"
            transition="scale-transition"
            width="80"
          />
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <div
        :class="[`text-body-3`]"
        class="text-right text-name"
        v-text="userName"
        v-if="isLoggedIn"
      ></div>
      <v-btn color="#3e206e" icon v-if="isLoggedIn" @click="logout">
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn
        color="#3e206e"
        icon
        v-if="isLoggedIn && esAdmin()"
        @click="configMenu = !configMenu"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn color="#3e206e" text to="/Login" v-else>
        <v-icon>mdi-account-circle-outline</v-icon>
        Acceder
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      width="300"
      v-model="configMenu"
      v-if="esAdmin && isLoggedIn"
      right
      clipped
      app
    >
      <v-list nav dense>
        <v-list-item-group color="primary">
          <v-list-item
            :key="index"
            v-for="(item, index) in menuItem"
            link
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
      <NotificationMessage />
    </v-main>
  </v-app>
</template>

<style>
@import "./assets/style/site.css";
</style>

<script>
import NotificationMessage from "./components/NotificationMessage.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",

  data: () => ({
    //
    configMenu: false,
    menuItem: [
      { title: "Usuarios", icon: "mdi-account", url: "/Usuarios" },
      { title: "Marcas", icon: "mdi-alpha-m-circle-outline", url: "/Marcas" },
      { title: "Modelos", icon: "mdi-alpha-m-circle", url: "/Modelos" },
      { title: "Versiones", icon: "mdi-car-multiple", url: "/Versiones" },
      { title: "Fabricaciones", icon: "mdi-car-wrench", url: "/Fabricaciones" },
    ],
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userName: function () {
      return this.$store.getters.userName;
    },
    roles: function () {
      let array = this.$store.getters.userRole;
      if (array == undefined) {
        array = [];
      }
      return array;
    },
  },
  methods: {
    logout: function () {
      let vm = this;
      this.$store.dispatch("logout").then(() => {
        vm.configMenu = false;
        this.$router.push("/").catch(() => {});
      });
    },
    esAdmin() {
      return (
        this.roles.includes("Administrador(a) del sistema") ||
        this.roles.includes("Administrador(a)")
      );
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  components: {
    NotificationMessage,
  },
  watch: {
    $route(to, from) {
      this.$store.state.piezas = [];
    },
  },
};
</script>
<style >
.text-name {
  color: #3e206e;
}
</style>
