import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import decode from 'jwt-decode'
import { checkAuthorized } from "../global";

Vue.use(Vuex)

const apiURL = "https://rigo.nubeteck.com/RigoApi/";
// const apiURL = "https://localhost:5001/";

export default new Vuex.Store({
  state: {
    marcas: [],
    modelos: [],
    anios: [],
    piezas: [],
    piezasAll: [],
    fabricaciones: [],
    versiones: [],
    notification: {},
    token: '',
    usuario: {},
    usuarioModel: {},
    usuarios: [],
    roles: [],
    detalle: {},
    model: {}
  },
  mutations: {
    SET_MARCAS: (state, marcas) => { state.marcas = marcas },
    SET_Usuarios: (state, usuarios) => { state.usuarios = usuarios },
    SET_UserModel: (state, usuarioModel) => { state.usuarioModel = usuarioModel },
    SET_MODELOS: (state, modelos) => { state.modelos = modelos },
    SET_ANIOS: (state, anios) => { state.anios = anios },
    SET_PIEZAS: (state, piezas) => { state.piezas = piezas },
    SET_ROLES: (state, roles) => { state.roles = roles },
    SET_PIEZA: (state, model) => { state.model = model },
    SET_model: (state, model) => { state.model = model },
    SET_PIEZASAll: (state, piezasAll) => { state.piezasAll = piezasAll },
    SET_FABRICACIONES: (state, fabricaciones) => { state.fabricaciones = fabricaciones },
    SET_VERSIONES: (state, versiones) => { state.versiones = versiones },
    PUSH_NOTIFICATION: (state, notification) => (state.notification = notification),
    SET_TOKEN: (state, token) => (state.token = token),
    LOAD_USER: (state, usuario) => (state.usuario = usuario),
    SET_DETALLE: (state, detalle) => (state.detalle = detalle),
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    GET_MARCAS(state) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/MarcasGet')
          .then(response => {
            state.commit('SET_MARCAS', response.data)
            resolve(response.data)
          })
      })

    },
    GET_MarcaModel({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/MarcaGetById'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id }
          }
        )
          .then(response => {
            commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    Agregar_Marca({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/MarcaForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      });
    },
    DELETE_Marca({ state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/MarcaCambiarEstado'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id, estadoId: 2 }
          }
        )
          .then(response => {
            // state.commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })
      })
    },
    GET_ROLES(state) {

      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/RolesGet')
          .then(response => {
            state.commit('SET_ROLES', response.data)
            resolve(response.data)
          })

      })
    },
    GET_USUARIOS(state) {
      axios.get(apiURL + 'RepuestosApi/UsuariosGet', {
        headers: {
          Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
        }
      })
        .then(response => {
          state.commit('SET_Usuarios', response.data)
        })
        .catch(err => { console.log(err) })
    },
    GET_UserModel(state, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/GetUsuario', {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }, params: { id: id }
        })
          .then(response => {
            state.commit('SET_UserModel', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    GET_MODELOS(state, marcaId) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/ModelosGet')
          .then(response => {
            state.commit('SET_MODELOS', response.data)
            resolve(response.data)
          })
          .catch(err => { reject(err) })

      })
    },
    GET_ModeloModel({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/ModeloGetById'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id }
          }
        )
          .then(response => {
            commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    Agregar_Modelo({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/ModelosForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      });
    },
    DELETE_Modelo({ state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/ModeloCambiarEstado'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id, estadoId: 2 }
          }
        )
          .then(response => {
            // state.commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    GET_PIEZAS(state, data) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/PiezasGet', { params: data })
          .then(response => {
            if (response.data.length == 0) {
              state.dispatch("addNotification", {
                success: false,
                message: 'No se encontraron piezas',
                show: true,
              });
            }
            state.commit('SET_PIEZAS', response.data)
            resolve(response.data)
          })
          .catch(err => { reject(err) })
      })

    },
    GET_PIEZA({state,commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/FormPiezas', { 
          headers: {
            Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
          }
          ,params: { piezaId: data.id, codigo: data.codigo } })
          .then(response => {
            commit('SET_PIEZA', response.data)
            resolve(response.data);
          })
      });

    },
    GET_PIEZASAll(state, data) {
      axios.get(apiURL + 'RepuestosApi/PiezasGetAll')
        .then(response => {
          state.commit('SET_PIEZASAll', response.data)
        })
    },
    GET_PiezaDetalle(state, piezaId) {
      axios.get(apiURL + 'RepuestosApi/PiezaDetalleGet', { params: { id: piezaId } })
        .then(response => {
          state.commit('SET_DETALLE', response.data)
        })
    },
    GET_Fabricaciones(state) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/FabricacionesGet')
          .then(response => {
            state.commit('SET_FABRICACIONES', response.data)
            resolve(response.data)
          })
      })
    },
    GET_FabricacionModel({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/FabricacionGetById'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id }
          }
        )
          .then(response => {
            commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    Agregar_Fabricacion({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/FabricacionesForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      });
    },
    DELETE_Fabricaciones({ state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/FabricacionCambiarEstado'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id, estadoId: 2 }
          }
        )
          .then(response => {
            // state.commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    GET_VERSIONES(state) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/VersionesGet')
          .then(response => {
            state.commit('SET_VERSIONES', response.data)
            resolve(response.data);
          })
      })
    },
    GET_VersionModel({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/VersionGetById'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id }
          }
        )
          .then(response => {
            commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    Agregar_Version({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/VersionesForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      });
    },
    DELETE_Version({ state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/VersionCambiarEstado'
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            },
            params: { id: id, estadoId: 2 }
          }
        )
          .then(response => {
            // state.commit('SET_model', response.data)
            resolve(response.data)
          })
          .catch(err => {
            checkAuthorized(err);
          })

      })
    },
    AGREGAR_PIEZA({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/PiezaCreate', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          })
          .then(response => {
            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });

            if (response.data.success) {
              resolve(response.data.success);
              router.push("/").catch(() => { });;
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      })
    },
    Desactivar_Usuario(state, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'RepuestosApi/EliminarUsuario', { params: { id: id } })
          .then(response => {
            state.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            resolve(response.data)
          })
      })
    },
    addNotification({ commit }, notification) {
      commit("PUSH_NOTIFICATION", notification);
    },
    login({ commit }, data) {
      return new Promise((resolve, reject) => {

        axios.post(apiURL + 'RepuestosApi/UserLogin', data)
          .then(response => {
            if (response.data.success) {
              commit("SET_TOKEN", response.data.token);
              commit("LOAD_USER", response.data);
              localStorage.setItem("token", response.data.token);
              router.push("/").catch(() => { });;
              resolve(response.data);
            } else {
              this.dispatch("addNotification", {
                success: response.data.success,
                message: response.data.message,
                show: true,
              });
            }
          })
      });
    },
    autoLogin({ commit }, location) {
      let token = localStorage.getItem("token");
      if (token) {
        let tokenvalue = decode(token);
        if (Date.now() < tokenvalue.exp * 1000) {
          commit("SET_TOKEN", token);
          commit("LOAD_USER", tokenvalue);
        }
        router.push('/').catch(() => { });
      }
    },
    Agregar_Usuario({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'RepuestosApi/UsuarioForm', data, {
          headers: {
            Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
          }
        })
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(err => {
            checkAuthorized(err);
          })
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token")
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userName: (state) => state.usuario.nombre + " " + state.usuario.apellido,
    userId: (state) => state.usuario.id,
    userRole: (state) => state.usuario.roles
  },
  modules: {
  }
})
