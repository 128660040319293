<template lang="">
    <div>
            <v-container>
              <v-card>
        <v-card-title> Fabricaciones </v-card-title>
        <v-divider></v-divider>
        <!-- Tabla de las piezas -->
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-btn text color="primary" @click="handledModel(0)">
              Agregar Fabricación <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-text-field
              label="Buscar"
              append-icon="mdi-magnify"
              outlined
              dense
              class="filtro"
              v-model="search"
            ></v-text-field>
          </div>
          <v-data-table :headers="headers" :items="fabricaciones" :search="search">
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="handledModel(item.fabricacionId)">
                    <v-list-item-icon
                      ><v-icon>mdi-pencil</v-icon></v-list-item-icon
                    ><v-list-item-content
                      ><v-list-item-title
                        >Editar</v-list-item-title
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item @click="eliminar(item.fabricacionId)">
                    <v-list-item-icon
                      ><v-icon>mdi-delete</v-icon></v-list-item-icon
                    ><v-list-item-content
                      ><v-list-item-title
                        >Eliminar</v-list-item-title
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Dialog de matenimiento -->
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form @submit.prevent="handleSubmit" ref="form">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-subheader>Fabricación</v-subheader>
                  <v-text-field
                    label="Nombre de la fabricación"
                    required
                    v-model="model.fabricacionNombre"
                    solo
                    clearable
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="invalid"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    </div>
</template>
<script>
export default {
  data: () => ({
    fabricaciones: [],
    search: "",
    loading: false,
    invalid: false,
    model: {},
    dialog: false,
    dialogTitle: "Nueva fabricación",
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      //confirmPassword:(v)=>v === this.password || 'Las contraseñas no coinciden.',
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: false,
        value: "fabricacionId",
      },
      { text: "Fabricación", value: "fabricacionNombre" },
      { text: "", value: "action", sortable: false },
    ],
  }),
  methods: {
    handleSubmit() {
      let vm = this;
      const data = {
        fabricacionId: vm.model.fabricacionId,
        fabricacionNombre: vm.model.fabricacionNombre,
      };
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Fabricacion", data).then((d) => {
          if (d.success) {
            vm.getAll();
            vm.dialog = false;
          }
        });
      }
    },
    handledModel(id) {
      let vm = this;
      vm.dialogTitle = id > 0 ? "Editar fabricación" : "Nueva fabricación";

      this.$store.dispatch("GET_FabricacionModel", id).then((d) => {
        this.model = d;
      });
      vm.dialog = true;
    },
    eliminar(id) {
      let vm = this;
      this.$store.dispatch("DELETE_Fabricaciones", id).then((d) => {
        if (d.success) {
          vm.$store.dispatch("addNotification", {
            success: d.success,
            message: "Fabricación eliminada",
            show: true,
          });
          vm.getAll();
        }
      });
    },
    getAll() {
      let vm = this;
      vm.$store.dispatch("GET_Fabricaciones").then((d) => {
        vm.fabricaciones = d;
      });
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>
<style lang="">
</style>