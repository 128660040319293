import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Detalle from '../views/Detalle.vue'
import Usuarios from '../views/Usuarios.vue'
import Marcas from '../views/Marcas.vue'
import Modelos from '../views/Modelos.vue'
import Versiones from '../views/Versiones.vue'
import Fabricaciones from '../views/Fabricaciones.vue'
import Login from '../components/Login.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Detalle',
    name: 'Detalle',
    component: Detalle
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Marcas',
    name: 'Marcas',
    component: Marcas,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Modelos',
    name: 'Modelos',
    component: Modelos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Versiones',
    name: 'Versiones',
    component: Versiones,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Fabricaciones',
    name: 'Fabricaciones',
    component: Fabricaciones,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/Login')
  } else {
    next()
  }
})

export default router
