import store from './store'

function checkAuthorized(response){
    if(response.response.status === 401){
        localStorage.removeItem("token");
        store.dispatch("autoLogin");
    }
    if(response.response.status === 403){
        localStorage.removeItem("token");
        store.dispatch("logout");
    }

}
export {checkAuthorized}