
<template>
    <v-snackbar
        v-model="notification.show"
        :color="notification.success?'green lighten-1':'red lighten-1'"
        :timeout="3000"
    >
        {{notification.message}}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="notification.show = false"
            >
                <v-icon>mdi-close</v-icon>
                </v-btn>
        </template>
        </v-snackbar>
</template>

<script>
import {mapState}from "vuex";
export default {
  data: () => ({
    text: "",
    success:false,
    snackbar:true
  }),
  computed:mapState(['notification'])
};
</script>

<style>
</style>