<template>
  <div class="content">
    <v-card>
      <v-card-title>
        <span class="text-h3">Iniciar sesión</span>
      </v-card-title>
      <v-row>
        <v-card-text>
          <v-form
            @submit.prevent="handleSubmit"
            ref="form"
          >
            <v-container>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    label="Usuario"
                    required
                    v-model="usuarioNombre"
                    solo
                    clearable
                    :rules="[rules.required]"
                    append-icon="mdi-account"
                  ></v-text-field>
                    </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    label="Contraseña"
                    required
                    v-model="password"
                    solo
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.required]"
                    @click:append="show = !show"
                  ></v-text-field>
                    </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="2"
                type="submit"
                :disabled="invalid"
                :loading="loading"
              >
                Acceder
                </v-btn>
            </v-container>
            </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>

</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  data: () => ({
    rules: {
      required: value => !!value || "Este campo es requerido.",
      min: v => v.length >= 8 || "Min 8 caracteres",
    },
    usuarioNombre: "",
    password: "",
    invalid: false,
    loading: false,
    show: false,
    snackbar: false,
    text: "",
    success: false,
  }),
  methods: {
    handleSubmit() {
      let vm = this;
      const data = {
        usuario: this.usuarioNombre,
        password: this.password,
      };
      if (this.$refs.form.validate()) {
        this.$store.dispatch("login", data)
      }
    },
  },

};
</script>